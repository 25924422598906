$brand-primary:         #353535; //#3a3b3b;//#2b475b;
$brand-secondary:       #7dbb40; //#88c946; // #95CA00;
$brand-accent:          #f39200;

$navbar-inverse-bg:   $brand-primary;
$navbar-inverse-link-active-color: #fff !default;
$navbar-inverse-border: $brand-secondary;

$btn-secondary-color:        #fff !default;
$btn-secondary-bg:           $brand-secondary !default;
$btn-secondary-border:       darken($btn-secondary-bg, 5%) !default;

$btn-accent-color:        #fff !default;
$btn-accent-bg:           $brand-accent !default;
$btn-accent-border:       darken($btn-accent-bg, 5%) !default;


@import "main";